@use "@angular/material" as mat;
@use "angular-material-contrast-fix";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/jetbrains-mono/400.css";
@import "@fontsource-variable/inter/standard.css";

$material-symbols-font-path: "~material-symbols/";
@import "material-symbols/rounded.css";
.material-symbols-rounded {
  font-variation-settings:
    "FILL" 0,
    "wght" 300,
    "GRAD" 0,
    "opsz" 24;
}

:root {
  --color-primary-50: var(--color-blue-50);
  --color-primary-100: var(--color-blue-100);
  --color-primary-200: var(--color-blue-200);
  --color-primary-300: var(--color-blue-300);
  --color-primary-400: var(--color-blue-400);
  --color-primary-500: var(--color-blue-500);
  --color-primary-600: var(--color-blue-600);
  --color-primary-700: var(--color-blue-700);
  --color-primary-800: var(--color-blue-800);
  --color-primary-900: var(--color-blue-900);

  --color-secondary-50: var(--color-violet-50);
  --color-secondary-100: var(--color-violet-100);
  --color-secondary-200: var(--color-violet-200);
  --color-secondary-300: var(--color-violet-300);
  --color-secondary-400: var(--color-violet-400);
  --color-secondary-500: var(--color-violet-500);
  --color-secondary-600: var(--color-violet-600);
  --color-secondary-700: var(--color-violet-700);
  --color-secondary-800: var(--color-violet-800);
  --color-secondary-900: var(--color-violet-900);
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
$font-family: "'Inter Variable', sans-serif";

$fonts: mat.m2-define-typography-config(
  $font-family: $font-family,
  $headline-1: mat.m2-define-typography-level(
      $font-size: 112px,
    ),
  $headline-2: mat.m2-define-typography-level(
      $font-size: 56px,
    ),
  $headline-3: mat.m2-define-typography-level(
      $font-size: 45px,
      $line-height: 48px,
    ),
  $headline-4: mat.m2-define-typography-level(
      $font-size: 34px,
      $line-height: 40px,
    ),
  $headline-5: mat.m2-define-typography-level(
      $font-size: 24px,
      $line-height: 32px,
    ),
  $headline-6: mat.m2-define-typography-level(
      $font-size: 20px,
      $line-height: 32px,
    ),
  $subtitle-1: mat.m2-define-typography-level(
      $font-size: 18px,
      $line-height: 28px,
    ),
  $subtitle-2: mat.m2-define-typography-level(
      $font-size: 18px,
      $line-height: 24px,
    ),
  $body-2: mat.m2-define-typography-level(
      $font-size: 16px,
    ),
  $body-1: mat.m2-define-typography-level(
      $font-size: 16px,
    ),
  $button: mat.m2-define-typography-level(
      $font-size: 14px,
    ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$primary-palette: (
  50: var(--color-primary-50),
  100: var(--color-primary-100),
  200: var(--color-primary-200),
  300: var(--color-primary-300),
  400: var(--color-primary-400),
  500: var(--color-primary-500),
  600: var(--color-primary-600),
  700: var(--color-primary-700),
  800: var(--color-primary-800),
  900: var(--color-primary-900),
  typography: $fonts,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    // ... continues to 900
  ),
);

$secondary-palette: (
  50: var(--color-secondary-50),
  100: var(--color-secondary-100),
  200: var(--color-secondary-200),
  300: var(--color-secondary-300),
  400: var(--color-secondary-400),
  500: var(--color-secondary-500),
  600: var(--color-secondary-600),
  700: var(--color-secondary-700),
  800: var(--color-secondary-800),
  900: var(--color-secondary-900),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    // ... continues to 900
  ),
);

$warn-palette: (
  50: var(--color-red-50),
  100: var(--color-red-100),
  200: var(--color-red-200),
  300: var(--color-red-300),
  400: var(--color-red-400),
  500: var(--color-red-500),
  600: var(--color-red-600),
  700: var(--color-red-700),
  800: var(--color-red-800),
  900: var(--color-red-900),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    // ... continues to 900
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.m2-define-palette($primary-palette);
$frontend-accent: mat.m2-define-palette($secondary-palette);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.m2-define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);
@include angular-material-contrast-fix.all-component-themes($frontend-theme);

.font-mat-icon {
  font-family: "Material Symbols Rounded";
}

// tailwindcss fix vertical line for mat-form-field
.mdc-notched-outline__notch {
  border-right: none;
  border-left: hidden !important;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none !important;
}
.mdc-button__label {
  @apply text-sm;
}

.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--focused):not(
    .mdc-text-field--disabled
  ) {
  .mdc-notched-outline > * {
    border-color: var(--color-gray-300) !important;
  }
}

.mat-mdc-form-field {
  margin-bottom: -1.25em !important;
}

.mat-mdc-text-field-wrapper {
  margin-top: 5px; // stop label being cut off
}

.mdc-button__label {
  z-index: unset !important; // fix stupid mdc issue where label goes above z-index of modals etc
}

.mat-mdc-row,
.mdc-data-table__content {
  font-family: inherit !important;
}

html .mat-mdc-row {
  height: unset;
}

.mat-calendar-table-header th {
  @apply text-xs;
  @apply font-normal;
  @apply text-gray-500;
}
.mat-calendar-body-cell-content {
  @apply text-xs;
  @apply font-normal;
  @apply text-gray-700;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.mat-calendar-body-selected {
  @apply text-white;
}
.mat-calendar-body-label {
  @apply text-sm;
  @apply text-gray-500;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-error-wrapper {
  @apply text-xs;
}

.mat-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.1 !important;
}

mat-datepicker-toggle .mat-button-wrapper svg {
  display: inline;
}
.mat-dialog-container {
  @apply rounded-md #{!important};
}
.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.mat-sort-header-content {
  text-align: unset !important;
}

th.text-right .mat-sort-header-content {
  text-align: right !important;
}

th.text-right .mat-sort-header-container {
  flex-direction: row-reverse;
}

// tippy
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/animations/shift-away.css";

.tippy-box {
  @apply rounded-md;
}

.tippy-box[data-theme~="dark"] {
  @apply bg-gray-900;
  @apply text-gray-100;
  @apply border-gray-900;
}

// ng-select
@import "@ng-select/ng-select/themes/default.theme.css";

ng-dropdown-panel {
  @apply rounded-md #{!important};
  @apply border-gray-300 #{!important};
  @apply text-sm #{!important};

  .ng-option {
    border-bottom: 1px solid white;
    &.ng-option-selected {
      background-color: var(--color-primary-100) !important;
      color: var(--color-primary-900) !important;
    }
    &.ng-option-marked {
      background-color: var(--color-primary-50) !important;
    }
    .ng-option-label {
      @apply text-sm #{!important};
    }
  }
}

ng-select {
  .ng-select-container {
    @apply rounded-md #{!important};
    @apply border-gray-300 #{!important};
  }
  &.ng-select-opened > .ng-select-container {
    @apply rounded-b-none #{!important};
  }

  .ng-value {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center;
    @apply bg-primary-100 #{!important};
    @apply rounded-md #{!important};
    @apply px-2 #{!important};
    @apply py-1 #{!important};
  }
  .ng-value-label {
    @apply text-sm;
    @apply px-2 #{!important};
    @apply text-primary-900 #{!important};
  }
  .ng-value-icon {
    border-right: none !important;
    border-radius: 50%;
    flex-shrink: 0;
    padding: 0 !important;
    @apply w-6 #{!important};
    @apply h-6 #{!important};
    text-align: center !important;
    line-height: 24px !important;
    &:hover {
      background-color: var(--color-primary-200) !important;
    }
  }

  .ng-placeholder {
    color: var(--color-gray-500);
    @apply text-sm #{!important};
    top: 50% !important;
    transform: translateY(-50%);
    padding-bottom: 0 !important;
  }

  input {
    @apply text-sm #{!important};
  }

  .ng-clear-wrapper {
    @apply text-gray-400 #{!important};
  }
  .ng-clear-wrapper:hover .ng-clear {
    @apply text-red-500 #{!important};
  }
  .ng-arrow-wrapper .ng-arrow {
    border-top-color: var(--color-gray-400) !important;
  }
  .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: var(--color-gray-800) !important;
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-feature-settings:
    "ss01" off,
    // alternate digits
    "cv05" on,
    // lowercase l
    "cv08" on,
    // uppercase I
    "ss03" on,
    // round quotes and commas
    "case" on,
    // case alternates
    "calt" on;
  // contextual alternates;
}
body {
  margin: 0;
  @apply text-gray-700;
}

mat-form-field {
  width: 100%;
}

a,
.prose a,
.prose-neutral a {
  @apply text-primary-500;
  @apply underline;
}

hr {
  @apply my-8;
  @apply h-px;
  @apply bg-gray-200;
  @apply border-0;
}

.mat-button-wrapper app-loading-spinner {
  @apply ml-2;
}

h1 {
  @apply text-gray-900;
}

h2 {
  @apply text-xl;
  @apply font-bold;
  @apply tracking-tight;
  @apply text-gray-900;
}

th {
  text-align: left;
  text-transform: capitalize;
}

:is(table):not(.mat-calendar-table) {
  @apply text-sm;
  @apply text-gray-600;

  @apply min-w-full;

  :is(tbody) {
    @apply divide-y;
  }

  :is(tfoot) {
    font-weight: bold;
    @apply text-gray-900;
  }

  tr:has(> th) {
    @apply bg-gray-50;
  }

  :is(th) {
    @apply align-bottom;
    @apply px-4;
    @apply py-2;
    @apply font-bold;
    @apply text-gray-800;
    @apply cursor-default;
    border-bottom: 1px solid var(--color-gray-200);
  }

  // :is(td), :is(th) {
  //   &:first-child {
  //     padding-left: 0;
  //   }
  //   &:last-child {
  //     padding-right: 0;
  //   }
  // }

  :is(td) {
    @apply px-4;
    @apply py-2;
    &:first-child {
      @apply text-gray-900;
    }
  }
}

/** Form styling */
.form-group {
  display: flex;
  flex-direction: column;
}

.label {
  @apply text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70;
}

.text-input {
  @apply border;
  @apply border-gray-300;
  @apply rounded;
  @apply px-3;
  @apply py-2;
  @apply text-sm;
  @apply text-gray-600;
  @apply focus:outline-none;
  @apply focus:border-primary-500;

  &.ng-invalid.ng-touched {
    @apply border-red-500;
  }
}

.checkbox {
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  display: inline-block;
  padding: 0;
  user-select: none;
  @apply w-5;
  @apply h-5;
  @apply text-primary-600;
  @apply bg-gray-100;
  @apply rounded;
  @apply border;
  @apply border-gray-300;
  @apply focus:ring-primary-500;
  @apply focus:ring-2;
  @apply checked:bg-primary-600;
  @apply checked:border-primary-600;

  &:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  }
}

.select-input {
  @apply block;
  @apply border;
  @apply border-gray-300;
  @apply bg-gray-50;
  @apply text-gray-900;
  @apply text-sm;
  @apply rounded-lg;
  @apply focus:ring-primary-500;
  @apply focus:border-primary-500;
  @apply w-full;
  @apply p-2.5;
}

.form-error {
  @apply text-red-500;
  @apply text-sm;
  @apply mt-1;
}

// toasts
.hot-toast-bar-base {
  @apply rounded-lg #{!important};
  @apply py-2 #{!important};
  @apply px-4 #{!important};
  @apply shadow-md;
}
.toast-success {
  --success-primary: var(--color-green-700);
  @apply bg-green-100 #{!important};
  @apply text-green-700 #{!important};
  .hot-toast-checkmark-icon {
    @apply bg-green-500 #{!important};
  }
}
.toast-error {
  --error-primary: var(--color-red-700);
  @apply bg-red-200 #{!important};
  @apply text-red-700 #{!important};
  .hot-toast-error-icon {
    @apply text-red-500 #{!important};
  }
}
.toast-loading {
  --loading-primary: var(--color-gray-700);
  @apply bg-gray-100 #{!important};
  @apply text-gray-700 #{!important};
}

// ngx-charts overrides
.ngx-charts-tooltip-content.animate {
  transition-duration: 0.2s !important;
}

.advanced-pie-legend .legend-items-container .legend-items {
  overflow: initial !important;
}
.advanced-pie-legend .total-value {
  @apply text-gray-900 #{!important};
}

.ngx-charts-outer {
  animation: none !important;
  display: flex;
  flex-direction: column;
}

.chart-legend .legend-wrap {
  width: 100% !important;
}

.legend-labels.horizontal-legend {
  background-color: var(--color-gray-100) !important;
  display: flex;
  width: 100%;
  @apply py-1;
  @apply px-2;
}

.chart-legend .legend-label {
  color: var(--color-gray-500) !important;
}

/**
  * Drag and drop
  */
.cdk-drag-preview {
  display: none;
}

.cdk-drag-animating,
.cdk-drag-placeholder,
.cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

[cdkDragHandle] {
  @apply cursor-grab;
}

/**
  * Print
  */

@media print {
  @page {
    size: letter portrait;
    margin: 0.75in;
  }

  * {
    print-color-adjust: exact;
  }

  body {
    width: 8.5in;
    overflow: visible;
  }

  hr {
    position: relative;
    height: 0.1mm;
    visibility: hidden;
    page-break-after: auto;
    break-after: auto;
  }

  table {
    position: relative;
    break-after: auto;
    break-inside: avoid;
  }

  section {
    position: relative;
    break-inside: avoid-page;
    break-after: auto;
    width: 100%;
  }

  .tippy-box {
    display: none !important;
  }
}
